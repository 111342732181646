import {
  MACRO_GRAMS_TEST_ID,
  MACRO_PERCENTAGE_TEST_ID,
  TOTAL_CARBS_TEST_ID,
  TOTAL_FAT_TEST_ID,
  TOTAL_PROTEIN_TEST_ID
} from 'constants/tests';
import { NutritionalFactDifferenceData } from 'types/product';
import { getMacroWrapperDataCyValue } from 'utils/tests';

import DifferenceBadge from '../DifferenceBadge/DifferenceBadge';

import {
  macroGramsStyles,
  macroNameStyles,
  macroPercentageStyles,
  macroWrapperStyles
} from './Macro.styles';
import { MacroName } from 'constants/products';

export type Props = {
  percentage: number;
  grams: number;
  macroName: string;
  color: string;
  differenceData?: NutritionalFactDifferenceData;
};

const getMacroTestId = (macroName: string): string => {
  switch (macroName) {
    case MacroName.PROTEIN:
      return TOTAL_PROTEIN_TEST_ID;
    case MacroName.FATS:
      return TOTAL_FAT_TEST_ID;
    case MacroName.CARBS:
      return TOTAL_CARBS_TEST_ID;
    default:
      return '';
  }
};

const Macro = ({
  percentage,
  grams,
  macroName,
  color,
  differenceData
}: Props) => {
  const wrapperDataCyValue = getMacroWrapperDataCyValue(macroName);

  const differenceDataBelongsToMacro = differenceData?.macro === macroName;

  return (
    <div data-cy={wrapperDataCyValue} className={macroWrapperStyles}>
      {differenceDataBelongsToMacro && (
        <DifferenceBadge
          value={differenceData.value}
          unit={differenceData.unit}
          color={color}
        />
      )}

      <p
        data-testid={`${macroName}_percentage`}
        className={macroPercentageStyles}
        style={{ color }}
      >
        {percentage}%
      </p>

      <p data-testid={getMacroTestId(macroName)} className={macroGramsStyles}>
        {grams}g
      </p>

      <p className={macroNameStyles}>{macroName}</p>
    </div>
  );
};

export default Macro;
