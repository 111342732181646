import CalorieCircle from './CalorieCircle/CalorieCircle';
import Macro from './Macro/Macro';

import { NutritionalFactDifferenceData, ResponseProduct } from 'types/product';

import { blue, green, yellow } from 'assets/styles/colors';

import { nutritionalFactsWrapperStyles } from './NutritionalFacts.styles';
import { UnitType } from 'contexts/ProductDisplayContext';
import { MacroName } from 'constants/products';
import { SortPrefix } from 'contexts/SortContext';

export type Props = {
  product: ResponseProduct;
  differenceData?: NutritionalFactDifferenceData;
  unit?: UnitType;
};

const NutritionalFacts = ({ product, differenceData, unit }: Props) => {
  const prefix = unit === 'package' ? SortPrefix.PACKAGE : SortPrefix.BASE;

  const protein = product[`${prefix}_nutrient_protein`];
  const fat = product[`${prefix}_nutrient_fat`];
  const carbs = product[`${prefix}_nutrient_carbs`];

  return (
    <div className={nutritionalFactsWrapperStyles}>
      <CalorieCircle
        differenceData={differenceData}
        product={product}
        prefix={prefix}
      />

      <Macro
        percentage={Math.round(product.base_carbs_percentage ?? 0)}
        grams={carbs ?? 0}
        macroName={MacroName.CARBS}
        color={blue}
        differenceData={differenceData}
      />

      <Macro
        percentage={Math.round(product.base_protein_percentage ?? 0)}
        grams={protein ?? 0}
        macroName={MacroName.PROTEIN}
        color={green}
        differenceData={differenceData}
      />

      <Macro
        percentage={Math.round(product.base_fat_percentage ?? 0)}
        grams={fat ?? 0}
        macroName={MacroName.FATS}
        color={yellow}
        differenceData={differenceData}
      />
    </div>
  );
};

export default NutritionalFacts;
