import { css } from '@emotion/css';
import { tabletSmall } from 'assets/styles/media-queries';
import { font } from 'assets/styles/utils';
import { font_sm, space1 } from 'assets/styles/variables';

export const priceWrapperStyles = css`
  display: flex;
`;

export const eurosStyles = css`
  font-size: ${font(2.8)};
  margin: 0;
  display: flex;
  flex-direction: column;
  margin-right: ${space1};

  ${tabletSmall} {
    font-size: ${font(2)};
  }
`;

export const centsWrapperStyles = css`
  display: flex;
  margin-top: ${space1};
  flex-direction: column;
`;

export const centStyles = css`
  margin: 0;
  font-size: ${font_sm};
`;

export const symbolStyles = css`
  font-size: ${font_sm};

  ${tabletSmall} {
    font-size: 7px;
  }
`;
