import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import ProductsList from 'components/ProductsList/ProductsList';
import { ResponseProduct } from 'types/product';
import styled from '@emotion/styled';
import {
  borderRadius,
  boxShadow10,
  boxShadow20,
  font_lg,
  font_md,
  font_xl,
  space2,
  space3,
  space4,
  space5,
  space6
} from 'assets/styles/variables';
import {
  mobileLarge,
  mobileSmall,
  tabletSmall
} from 'assets/styles/media-queries';
import { gray10, gray20, white } from 'assets/styles/colors';
import { orange } from 'assets/styles/colors';
import { fetchFromApi } from 'api/fetch';
import { useSortContext, SortKeys, SortOrder } from 'contexts/SortContext';
import { useProductDisplay } from 'contexts/ProductDisplayContext';
import { Skeleton } from 'antd';
import {
  SIMILAR_PRODUCTS_LIST_SKELETON_TEST_ID,
  SORT_BY_BUTTON_TEST_ID,
  SORT_ORDER_BUTTON_TEST_ID
} from 'constants/tests';

type Props = {
  limit?: number;
  singleProduct?: ResponseProduct;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${space6};
  width: 100%;
`;

const SortingControls = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${space5};

  ${mobileSmall} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const SortByLabel = styled.label`
  font-size: ${font_xl};
  margin-right: ${space5};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  ${tabletSmall} {
    word-wrap: break-word;
    font-size: ${font_lg};

    display: inline;
    -webkit-line-clamp: inherit;
    -webkit-box-orient: inherit;

    width: 88%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  ${mobileLarge} {
    font-size: ${font_md};
  }

  ${mobileSmall} {
    margin-bottom: ${space2};
  }
`;

const DropdownWrapper = styled.div`
  position: relative;
  min-width: 150px;
  margin-right: ${space2};
`;

const DropdownButton = styled.button`
  width: 100%;
  padding: ${space2} ${space4} ${space2} ${space3};
  border: 1px solid ${gray20};
  border-radius: 4px;
  background-color: white;
  font-size: ${font_md};
  cursor: pointer;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: border-color 200ms ease-in-out;

  &:hover {
    border-color: ${orange};
  }

  &:focus {
    outline: none;
    border-color: ${orange};
    box-shadow: ${boxShadow10};
  }
`;

const DropdownList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin: ${space2} 0;
  padding: 0;
  list-style: none;
  background-color: ${white};
  border-radius: ${borderRadius};
  box-shadow: ${boxShadow20};
  z-index: 1000;
`;

const Arrow = styled.span<{ isOpen: boolean }>`
  border: solid ${gray20};
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform-origin: center 50%;
  transform: ${({ isOpen }) =>
    isOpen
      ? 'translateY(2px) rotate(-135deg)'
      : 'translateY(-2px) rotate(45deg)'};
  transition: transform 0.2s ease;
`;

const DropdownItem = styled.li<{ isSelected?: boolean }>`
  padding: ${space2} ${space3};
  cursor: pointer;
  background-color: ${({ isSelected }) => (isSelected ? gray10 : 'white')};

  &:hover {
    background-color: ${gray20};
  }
`;

const SortBySelect = ({
  options
}: {
  options: Partial<Record<SortKeys, { label: string }>>;
}) => {
  const { sortBy, handleSortByChange } = useSortContext();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <DropdownWrapper ref={dropdownRef}>
      <DropdownButton
        data-testid={SORT_BY_BUTTON_TEST_ID}
        onClick={() => setIsOpen(!isOpen)}
      >
        {options[sortBy as keyof typeof options]?.label}
        <Arrow isOpen={isOpen} />
      </DropdownButton>
      {isOpen && (
        <DropdownList>
          {Object.entries(options).map(([value, { label }]) => (
            <DropdownItem
              key={value}
              isSelected={value === sortBy}
              onClick={() => {
                handleSortByChange(value as SortKeys);
                setIsOpen(false);
              }}
              data-testid={`sort-by-${label.toLowerCase().replace(/_/g, '-')}`}
            >
              {label}
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </DropdownWrapper>
  );
};

const SortOrderSelect = () => {
  const { sortOrder, handleSortOrderChange } = useSortContext();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const options = {
    desc: { label: 'High to low' },
    asc: { label: 'Low to high' }
  };

  return (
    <DropdownWrapper ref={dropdownRef}>
      <DropdownButton
        data-testid={SORT_ORDER_BUTTON_TEST_ID}
        onClick={() => setIsOpen(!isOpen)}
      >
        {options[sortOrder].label}
        <Arrow isOpen={isOpen} />
      </DropdownButton>
      {isOpen && (
        <DropdownList>
          {Object.entries(options).map(([value, { label }]) => (
            <DropdownItem
              key={value}
              isSelected={value === sortOrder}
              onClick={() => {
                handleSortOrderChange(value as SortOrder);
                setIsOpen(false);
              }}
            >
              {label}
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </DropdownWrapper>
  );
};

const SimilarProductsList = ({ limit = 12, singleProduct }: Props) => {
  const [products, setProducts] = useState<ResponseProduct[]>([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const { sortBy, sortOrder } = useSortContext();
  const { activeUnit } = useProductDisplay();

  const SORT_OPTIONS: Partial<Record<SortKeys, { label: string }>> = {
    [activeUnit === 'package'
      ? SortKeys.PACKAGE_CALORIES
      : SortKeys.BASE_CALORIES]: { label: 'Calories' },
    [SortKeys.PROTEIN_PERCENT]: { label: 'Protein / Calorie' },
    [SortKeys.FAT_PERCENT]: { label: 'Fat / Calorie' },
    [SortKeys.CARBS_PERCENT]: { label: 'Carbs / Calorie' },
    [activeUnit === 'package'
      ? SortKeys.TOTAL_PACKAGE_PROTEIN
      : SortKeys.TOTAL_PROTEIN]: { label: 'Total protein' },
    [activeUnit === 'package'
      ? SortKeys.TOTAL_PACKAGE_FAT
      : SortKeys.TOTAL_FAT]: { label: 'Total fat' },
    [activeUnit === 'package'
      ? SortKeys.TOTAL_PACKAGE_CARBS
      : SortKeys.TOTAL_CARBS]: { label: 'Total carbs' },
    [SortKeys.PROTEIN_PER_EURO]: { label: 'Protein / Euro' },
    [SortKeys.FAT_PER_EURO]: { label: 'Fat / Euro' },
    [SortKeys.CARBS_PER_EURO]: { label: 'Carbs / Euro' }
  };

  useEffect(() => {
    const fetchSimilarProducts = async () => {
      try {
        setLoading(true);
        const params = {
          sort_by: sortBy,
          sort_order: sortOrder,
          limit: String(limit)
        };

        const data = await fetchFromApi<ResponseProduct[]>(
          `/products/similar/${id}`,
          params
        );
        setProducts(data);
      } catch (error) {
        console.error('Error fetching similar products:', error);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchSimilarProducts();
    }
  }, [id, sortBy, sortOrder, limit]);

  return (
    <Wrapper>
      <SortingControls>
        <SortByLabel>Sort by: </SortByLabel>
        <SortBySelect options={SORT_OPTIONS} />
        <SortOrderSelect />
      </SortingControls>

      {loading ? (
        <Skeleton
          data-testid={SIMILAR_PRODUCTS_LIST_SKELETON_TEST_ID}
          active
          paragraph={{ rows: 6 }}
        />
      ) : (
        <ProductsList products={products} singleProduct={singleProduct} />
      )}
    </Wrapper>
  );
};

export default SimilarProductsList;
