import { useNavigate } from 'react-router';

import { PRODUCT_URL_SEGMENT } from 'constants/routes';
import {
  PRODUCT_SEARCH_ITEM_NAME_TEST_ID,
  PRODUCT_SEARCH_ITEM_TEST_ID
} from 'constants/tests';

import ProductImage from 'components/ProductImage/ProductImage';

import {
  wrapperStyles,
  imageWrapperStyles,
  imageStyles,
  nameStyles
} from './ProductSearchItem.styles';

type Props = { id: string; name: string; onClick: () => void };

const ProductSearchItem = ({ id, name, onClick }: Props) => {
  const navigate = useNavigate();

  const handleOnItemClick = () => {
    navigate(`/${PRODUCT_URL_SEGMENT}/${id}`);
    onClick();
  };

  return (
    <div
      data-testid={PRODUCT_SEARCH_ITEM_TEST_ID}
      onClick={handleOnItemClick}
      className={wrapperStyles}
    >
      <ProductImage
        wrapperStyles={imageWrapperStyles}
        imageStyles={imageStyles}
        productId={id}
      />

      <p data-testid={PRODUCT_SEARCH_ITEM_NAME_TEST_ID} className={nameStyles}>
        {name}
      </p>
    </div>
  );
};

export default ProductSearchItem;
