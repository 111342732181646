import { useTranslation } from 'react-i18next';

import { PRODUCT_NOT_FOUND_TEST_ID } from 'constants/tests';
import { ReactComponent as MagnifyingGlassIcon } from 'assets/img/magnifying-glass.svg';

import {
  iconStyles,
  textStyles,
  wrapperStyles
} from './ProductsNotFound.styles';

const ProductsNotFound = () => {
  const { t } = useTranslation();

  return (
    <div data-testid={PRODUCT_NOT_FOUND_TEST_ID} className={wrapperStyles}>
      <MagnifyingGlassIcon className={iconStyles} />

      <h2 className={textStyles}>{t('productsNotFound')}</h2>
    </div>
  );
};

export default ProductsNotFound;
