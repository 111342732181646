import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import debounce from 'lodash/debounce';

import { ResponseProduct } from 'types/product';
import { Status } from 'types/api';
import { fetchFromApi } from 'api/fetch';

import ResultsPanel from './ResultsPanel/ResultsPanel';
import SearchInput from './SearchInput/SearchInput';

import { wrapperStyles } from './ProductSearch.styles';

const ProductSearch = () => {
  const [searchValue, setSearchValue] = useState('');
  const [status, setStatus] = useState<Status>(Status.Idle);
  const [resultsPanelIsVisible, setResultsPanelIsVisible] =
    useState<boolean>(false);

  useEffect(() => {
    setResultsPanelIsVisible(searchValue.length >= 2);
  }, [searchValue]);

  const fetchProducts = async (search: string): Promise<ResponseProduct[]> => {
    try {
      if (!search) return [];

      const isEan = /^\d+$/.test(search);
      if (isEan && search.length < 8) {
        throw new Error('EAN must be at least 8 digits');
      }

      const baseParams = { limit: '12' };
      const searchParams = {
        ...baseParams,
        ...(isEan ? { ean: search } : { name: search })
      };

      try {
        return await fetchFromApi<ResponseProduct[]>(
          '/products/search',
          searchParams
        );
      } catch (apiError: any) {
        if (apiError.status === 404) {
          return [];
        }

        throw apiError;
      }
    } catch (error) {
      console.error('Error fetching products:', error);
      throw error;
    }
  };

  const debouncedSearch = useMemo(
    () =>
      debounce((term: string) => {
        setSearchValue(term);
      }, 300),
    []
  );

  const {
    data: products,
    isLoading,
    error
  } = useQuery({
    queryKey: ['products', searchValue],
    queryFn: () => fetchProducts(searchValue),
    enabled: searchValue.length >= 2
  });

  useEffect(() => {
    if (error) {
      setStatus(Status.Rejected);
    } else if (isLoading) {
      setStatus(Status.Pending);
    } else if (products) {
      setStatus(products.length === 0 ? Status.NotFound : Status.Resolved);
    }
  }, [error, isLoading, products]);

  const makeResultsPanelVisibleIfHasSearchValue = useCallback(() => {
    const hasSearchValue = searchValue !== '';
    if (hasSearchValue) {
      setResultsPanelIsVisible(true);
    }
  }, [searchValue]);

  useEffect(() => {
    makeResultsPanelVisibleIfHasSearchValue();
  }, [makeResultsPanelVisibleIfHasSearchValue]);

  const clearSearchValue = () => setSearchValue('');

  const saveSearchValueToState = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    debouncedSearch(event.target.value);
    setSearchValue(event.target.value);
  };

  return (
    <div className={wrapperStyles}>
      <SearchInput
        onChange={event => saveSearchValueToState(event)}
        onFocus={makeResultsPanelVisibleIfHasSearchValue}
        searchValue={searchValue}
        onCloseIconClick={() => {
          clearSearchValue();
          setResultsPanelIsVisible(false);
        }}
      />

      <ResultsPanel
        isVisible={resultsPanelIsVisible}
        setIsVisible={setResultsPanelIsVisible}
        searchStatus={status}
        productResults={products ?? []}
      />
    </div>
  );
};

export default ProductSearch;
