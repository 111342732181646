import { MacroName } from './products';

export const PAGE_WRAPPER_TEST_ID = 'PageWrapper';

export const SEARCH_INPUT_TEST_ID = 'SearchInput_input';
export const SEARCH_INPUT_CLOSE_ICON_TEST_ID = 'SearchInput_close_icon';

export const PRODUCT_CATEGORY_TITLE_TEST_ID = 'ProductCategoryTitle';
export const PRODUCT_SEARCH_ITEM_TEST_ID = 'ProductSearchItem';
export const PRODUCT_SEARCH_ITEM_NAME_TEST_ID = 'ProductSearchItem_name';
export const PRODUCT_SEARCH_ITEM_IMAGE_TEST_ID = 'ProductSearchItem_image';
export const PRODUCT_NOT_FOUND_TEST_ID = 'ProductsNotFound';

export const CATEGORY_ITEM_TEST_ID = 'CategoryItem';
export const CATEGORY_EXPANSION_BUTTON_TEST_ID = 'CategoryExpansionButton';

export const PRODUCTS_LIST_TEST_ID = 'ProductsList';

export const NAVIGATION_BACK_BUTTON_TEST_ID = 'Navigation_BackButton';

export const RESULTS_PANEL_WRAPPER_TEST_ID = 'ResultsPanel_wrapper';
export const RESULTS_PANEL_SKELETON_WRAPPER_TEST_ID =
  'ResultsPanel_Skeleton_wrapper';

export const CATEGORY_PRODUCTS_WRAPPER_TEST_ID = 'categoryAndProductsWrapper';

export const PRODUCT_CARD_TEST_ID = 'ProductCard';
export const PRODUCT_CARD_NAME_TEST_ID = 'ProductCard_name';
export const SINGLE_PRODUCT_CARD_TEST_ID = 'SingleProductCard';
export const SINGLE_PRODUCT_CARD_NAME_TEST_ID = 'SingleProductCard_name';
export const PRODUCT_IMAGE_WRAPPER_TEST_ID = 'ProductImage_wrapper';

export const SORT_DRAWER_TEST_ID = 'SortDrawer_Drawer';
export const SORT_BY_BUTTON_TEST_ID = 'SortByButton';
export const SORT_ORDER_BUTTON_TEST_ID = 'SortOrderButton';
export const SORT_BUTTON_ACTIVE_MARKER_TEST_ID = 'SortButton_ActiveMarker';
export const SORT_ITEM_RADIO_TEST_ID = 'SortItem_input';

export const CALORIE_CIRCLE_WRAPPER_TEST_ID = 'CalorieCircle_wrapper';
export const NUTRIENTS_CIRCLE_WRAPPER_TEST_ID = 'NutrientsCircleWrapper';
export const CALORIE_COUNT_TEST_ID = 'NutrientsCircleWrapper_calorieCount';
export const MACRO_PERCENTAGE_TEST_ID = 'Macro_percentage';
export const MACRO_GRAMS_TEST_ID = 'Macro_grams';
export const DIFFERENCE_BADGE_TEST_ID = 'DifferenceBadge';
export const DIFFERENCE_BADGE_SEPARATOR_TEST_ID = 'DifferenceBadge_separator';

export const SIMILAR_PRODUCTS_LIST_SKELETON_TEST_ID =
  'SimilarProductsListSkeleton';

export const PROTEIN_PERCENTAGE_TEST_ID = `${MacroName.PROTEIN}_percentage`;
export const FAT_PERCENTAGE_TEST_ID = `${MacroName.FATS}_percentage`;
export const CARBS_PERCENTAGE_TEST_ID = `${MacroName.CARBS}_percentage`;
export const TOTAL_PROTEIN_TEST_ID = `${MacroName.PROTEIN}_grams`;
export const TOTAL_FAT_TEST_ID = `${MacroName.FATS}_grams`;
export const TOTAL_CARBS_TEST_ID = `${MacroName.CARBS}_grams`;
