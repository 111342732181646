import { ResponseProduct } from 'types/product';

import ProductCard from './ProductCard/ProductCard';

import { ProductsListWrapperStyles } from './ProductsList.styles';
import { determineHighlight } from './utils';
import { calculateDifference } from './utils';
import { useSortContext } from 'contexts/SortContext';
import { PRODUCTS_LIST_TEST_ID } from 'constants/tests';

export type Props = {
  products: ResponseProduct[] | undefined;
  singleProduct?: ResponseProduct;
};
const ProductsList = ({ products, singleProduct }: Props) => {
  const { sortBy, sortOrder } = useSortContext();

  return (
    <div
      data-testid={PRODUCTS_LIST_TEST_ID}
      className={ProductsListWrapperStyles}
    >
      {products?.map(product => {
        return (
          <ProductCard
            highlight={determineHighlight(
              product,
              singleProduct,
              sortBy as keyof ResponseProduct,
              sortOrder
            )}
            differenceData={calculateDifference(
              product,
              singleProduct,
              sortBy as keyof ResponseProduct,
              sortOrder
            )}
            key={product.meta_ean}
            product={product}
          />
        );
      })}
    </div>
  );
};

export default ProductsList;
