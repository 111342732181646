import { NutritionalFactUnit, ResponseProduct } from 'types/product';
import { NutritionalFactDifferenceData } from 'types/product';

export const determineHighlight = (
  product: ResponseProduct,
  singleProduct: ResponseProduct | undefined,
  sortBy: keyof ResponseProduct,
  sortOrder: 'asc' | 'desc'
): boolean => {
  if (!singleProduct) return false;

  const productValue = product[sortBy];
  const singleProductValue = singleProduct[sortBy];

  return sortOrder === 'desc'
    ? (productValue ?? 0) > (singleProductValue ?? 0)
    : (productValue ?? 0) < (singleProductValue ?? 0);
};

export const calculateDifference = (
  product: ResponseProduct,
  singleProduct: ResponseProduct | undefined,
  sortBy: keyof ResponseProduct,
  sortOrder: 'asc' | 'desc'
): NutritionalFactDifferenceData | undefined => {
  if (!singleProduct) return undefined;

  const productValue = product[sortBy];
  const singleProductValue = singleProduct[sortBy];

  if (productValue === undefined || singleProductValue === undefined) {
    return undefined;
  }

  const difference = Number(productValue) - Number(singleProductValue);

  if (
    (sortOrder === 'asc' && difference >= 0) ||
    (sortOrder === 'desc' && difference <= 0)
  ) {
    return undefined;
  }

  const macroMapping: Record<
    string,
    { macro: string; unit: NutritionalFactUnit }
  > = {
    // 100gml mappings
    base_protein_percentage: { macro: 'Protein', unit: '%' },
    base_carbs_percentage: { macro: 'Carbs', unit: '%' },
    base_fat_percentage: { macro: 'Fats', unit: '%' },
    base_nutrient_protein: { macro: 'Protein', unit: 'g' },
    base_nutrient_carbs: { macro: 'Carbs', unit: 'g' },
    base_nutrient_fat: { macro: 'Fats', unit: 'g' },
    base_nutrient_energy_kcal: { macro: 'Calories', unit: 'cal' },
    // package mappings
    package_nutrient_protein: { macro: 'Protein', unit: 'g' },
    package_nutrient_carbs: { macro: 'Carbs', unit: 'g' },
    package_nutrient_fat: { macro: 'Fats', unit: 'g' },
    package_nutrient_energy_kcal: { macro: 'Calories', unit: 'cal' },
    grams_protein_per_euro: { macro: 'Protein', unit: 'g' },
    grams_carbs_per_euro: { macro: 'Carbs', unit: 'g' },
    grams_fat_per_euro: { macro: 'Fats', unit: 'g' }
  };

  const mappingInfo = macroMapping[sortBy];

  if (!mappingInfo) return undefined;

  return {
    unit: mappingInfo.unit,
    macro: mappingInfo.macro,
    value: Math.round(difference)
  };
};
